<template>
  <b-dropdown id="dropdown-1" variant="light" size="sm">
    <template v-if="lang == 'nl'" slot="button-content">
      <icon-base icon-name="flag-nl"><icon-flag-nl /></icon-base>
    </template>
    <template v-if="lang == 'en'" slot="button-content">
      <icon-base icon-name="flag-us"><icon-flag-us /></icon-base>
    </template>
    <b-dropdown-item v-if="lang != 'nl'" @click="switchLang()">
      <icon-base icon-name="flag-nl"><icon-flag-nl /></icon-base>
    </b-dropdown-item>
    <b-dropdown-item v-if="lang != 'en'" @click="switchLang()">
      <icon-base icon-name="flag-us"><icon-flag-us /></icon-base>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapMutations } from 'vuex'
import IconBase from './icons/IconBase'
import IconFlagUs from './icons/IconFlagUs'
import IconFlagNl from './icons/IconFlagNl'
import { BDropdownItem, BDropdown } from 'bootstrap-vue'

export default {
  name: 'LanguageSelector',
  components: {
    IconBase,
    IconFlagUs,
    IconFlagNl,
    'b-dropdown': BDropdown,
    'b-dropdown-item': BDropdownItem,
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
  },
  methods: {
    ...mapMutations(['switchLang']),
  },
}
</script>
