<template>
  <b-navbar
    toggleable="lg"
    class="border-primary border-top border-bottom nav-1 mt-3"
  >
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="mx-auto">
        <b-nav-item :to="'/'" id="home">
          {{ $t('message.menu.home') }}
        </b-nav-item>
        <b-nav-item :to="'/about'">
          {{ $t('message.menu.about') }}
        </b-nav-item>
        <b-nav-item :to="'/coaching'">
          {{ $t('message.menu.coaching') }}
        </b-nav-item>
        <b-nav-item :to="'/blog'">
          {{ $t('message.menu.blog') }}
        </b-nav-item>
        <b-nav-item :to="'/contact'">
          {{ $t('message.menu.contact') }}
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import {
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BNavItem,
  BNavbarNav,
} from 'bootstrap-vue'

export default {
  name: 'HeaderMenu',
  components: {
    'b-navbar': BNavbar,
    'b-navbar-toggle': BNavbarToggle,
    'b-collapse': BCollapse,
    'b-navbar-nav': BNavbarNav,
    'b-nav-item': BNavItem,
  },
}
</script>

<style scoped lang="scss">
.navbar-nav {
  #home {
    .nav-link {
      &.router-link-exact-active {
        color: $link-selected-color;
      }
    }
  }
  :not(#home) {
    .nav-link {
      &.router-link-active {
        color: $link-selected-color;
      }
    }
  }
}
</style>
