import { ApiService, ApiError } from './api.service'

const AuthService = {
  token: async function (name, password) {
    const resource = '/wordpress/wp-json/jwt-auth/v1/token'

    var formData = new FormData()
    formData.set('username', name)
    formData.set('password', password)

    try {
      const response = await ApiService.post(resource, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return response.data
    } catch (error) {
      throw new ApiError(error.response.status, error.response.data.message)
    }
  },
}

export default AuthService

export { AuthService, ApiError }
