<template>
  <a href="mailto:info@zijschittert.nl" @click="onClick">
    info@zijschittert.nl
  </a>
</template>

<script>
export default {
  name: 'EmailLink',
  methods: {
    onClick() {
      this.$ga.event('contact', 'email click')
    },
  },
}
</script>
