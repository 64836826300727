<template>
  <ul v-if="variant == 'list'" class="list-unstyled">
    <li v-for="(link, index) in links" :key="index">
      <a :href="link.url" target="_blank" @click="onClick(link.name)">
        <font-awesome-icon :icon="['fab', link.faName]" fixed-width />
        <span class="pl-1">{{ link.name }}</span>
      </a>
    </li>
  </ul>

  <div v-else>
    <a
      v-for="(link, index) in links"
      :key="index"
      :href="link.url"
      target="_blank"
      class="pl-3"
      @click="onClick(link.name)"
    >
      <font-awesome-icon :icon="['fab', link.faName]" size="sm" />
    </a>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faInstagram,
  faFacebookF,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'
library.add(faInstagram, faFacebookF, faWhatsapp)

export default {
  name: 'SocialLinks',
  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    variant: String, // list
  },
  data() {
    return {
      links: [
        {
          faName: 'instagram',
          url: 'https://www.instagram.com/zij_schittert_coaching',
          name: 'Instagram',
        },
        {
          faName: 'facebook-f',
          url: 'https://www.facebook.com/Zij-schittert-107157514198807',
          name: 'Facebook',
        },
      ],
    }
  },
  methods: {
    onClick(linkname) {
      this.$ga.event('contact', 'social click', linkname)
    },
  },
}
</script>
