<template>
  <footer class="mt-auto bg-dark">
    <div class="footer-nav">
      <div class="container pt-3 type-footer-content">
        <b-row>
          <b-col sm="3" class="type-footer-head d-none d-sm-block">{{
            $t('message.head.title')
          }}</b-col>
          <b-col cols="6" sm="3" class="pl-sm-0">
            <ul class="list-unstyled">
              <li><router-link to="/contact">Contact</router-link></li>
              <!-- <li><a href="#">Algemene voorwaarden</a></li> -->
              <li>
                <a
                  href="/Privacy en cookie verklaring - zijschittert.nl.pdf"
                  target="_blank"
                >
                  {{ $t('message.footer.linkPrivacy') }}
                </a>
              </li>
              <li>KvK-nr: 81342160</li>
              <li>Btw-id: NL003563174B95</li>
            </ul>
          </b-col>
          <b-col cols="6" sm="3">
            <social-links variant="list" />
          </b-col>
          <b-col cols="12" sm="3" class="pr-sm-0">
            <ul class="list-unstyled">
              <!-- <li>497 Evergreen Rd. Roseville, CA 95673</li> -->
              <li><whatsapp-link /></li>
              <li><email-link /></li>
              <li><login-link /></li>
            </ul>
          </b-col>
        </b-row>
      </div>
    </div>
    <div>
      <div class="container text-light bottom-footer">
        <b-row>
          <b-col
            sm="8"
            class="mr-auto px-sm-0 type-footer-small text-center text-sm-left"
            align-self="center"
          >
            copyright © {{ new Date().getFullYear() }} zijschittert.nl | website
            by
            <a href="https://tervelde.com" target="_blank" class="tervelde"
              >tervelde.com</a
            >
          </b-col>
          <b-col sm="4" class="py-2 px-sm-0 text-center text-sm-right">
            <social-links />
          </b-col>
        </b-row>
      </div>
    </div>
    <cookie-law
      theme="dark-lime"
      :buttonText="$t('message.footer.cookieAccept')"
    >
      <div slot="message">
        {{ $t('message.footer.cookie') }}
      </div>
    </cookie-law>
  </footer>
</template>

<script>
import SocialLinks from './SocialLinks'
import WhatsappLink from './WhatsappLink'
import EmailLink from './EmailLink'
import LoginLink from './LoginLink'
import { BRow, BCol } from 'bootstrap-vue'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'FooterFixed',
  components: {
    SocialLinks,
    WhatsappLink,
    EmailLink,
    LoginLink,
    CookieLaw,
    'b-row': BRow,
    'b-col': BCol,
  },
}
</script>

<style scoped lang="scss">
.footer-nav {
  background-color: $footer-fixed-nav-background-color;
}
.bottom-footer ::v-deep a {
  color: $white;
}
</style>
