<template>
  <div>
    <b-link v-if="token" @click="logout()">Logout</b-link>
    <b-link v-else v-b-modal.modal-login>Login</b-link>

    <b-modal
      id="modal-login"
      ref="modal"
      title="Login"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :state="nameState"
          label="Password"
          label-for="password-input"
          invalid-feedback="Password is required"
        >
          <b-form-input
            id="password-input"
            v-model="password"
            :state="passwordState"
            required
            type="password"
          ></b-form-input>
        </b-form-group>
      </form>
      <p v-if="errorMessage" v-html="errorMessage"></p>
    </b-modal>
  </div>
</template>

<script>
import { AuthService } from '../services/auth.service'
import { BModal, BLink, VBModal, BFormInput, BFormGroup } from 'bootstrap-vue'

export default {
  name: 'LoginLink',
  components: {
    'b-link': BLink,
    'b-modal': BModal,
    'b-form-group': BFormGroup,
    'b-form-input': BFormInput,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      name: '',
      nameState: null,
      password: '',
      passwordState: null,
      submitting: false,
      submitted: false,
      errorMessage: undefined,
      token: undefined,
    }
  },
  watch: {
    submitted(newValue) {
      if (newValue) this.$refs.modal.hide()
    },
    token(newValue) {
      if (newValue) {
        sessionStorage.token = newValue
      } else {
        sessionStorage.removeItem('token')
      }
    },
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid ? 'valid' : 'invalid'
      this.passwordState = valid ? 'valid' : 'invalid'
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
      this.password = ''
      this.passwordState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.submitLogin()
    },
    submitLogin() {
      this.$ga.event('login', 'name', this.name)
      this.submitting = true
      this.submitted = false
      this.errorMessage = undefined
      AuthService.token(this.name, this.password)
        .then((response) => {
          this.submitted = true
          this.token = response.token
        })
        .catch((error) => {
          this.errorMessage = error.message
        })
        .finally(() => (this.submitting = false))
    },
    logout() {
      this.token = undefined
    },
  },
  mounted() {
    this.token = sessionStorage.token
  },
}
</script>

<style scoped lang="scss"></style>
