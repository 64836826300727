<template>
  <picture v-if="picture">
    <source
      v-for="(source, sourceindex) in picture.sources"
      :key="sourceindex"
      :media="source.media"
      :srcset="require(`@/assets/${source.srcset}`)"
    />
    <img
      class="img-fluid"
      :src="require(`@/assets/${picture.src}`)"
      :alt="picture.alt"
    />
  </picture>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LogoPicture',
  props: {
    variant: {
      type: String,
      default: 'normal',
      required: false,
    },
  },
  data() {
    return {
      picture: undefined,
      img: 'Zij_Schittert_315.png',
    }
  },
  computed: {
    ...mapState(['lang']),
  },
  watch: {
    lang(newValue) {
      this.getContent(newValue)
    },
  },
  methods: {
    getContent(lang) {
      switch (this.variant + '_' + lang) {
        case 'normal_nl':
          this.picture = {
            alt: 'Zij Schittert Logo',
            src: 'Zij_Schittert_315.png',
            sources: [
              {
                media: '(min-width: 75em)',
                srcset: 'Zij_Schittert_550.png',
              },
              {
                media: '(min-width: 48em)',
                srcset: 'Zij_Schittert_470.png',
              },
              {
                media: '(min-width: 34em)',
                srcset: 'Zij_Schittert_400.png',
              },
            ],
          }
          break
        case 'normal_en':
          this.picture = {
            alt: 'She Shines Logo',
            src: 'She_Shines_315.png',
            sources: [
              {
                media: '(min-width: 75em)',
                srcset: 'She_Shines_500.png',
              },
              {
                media: '(min-width: 48em)',
                srcset: 'She_Shines_470.png',
              },
              {
                media: '(min-width: 34em)',
                srcset: 'She_Shines_400.png',
              },
            ],
          }
          break
        case 'gold_nl':
          this.picture = {
            alt: 'Zij Schittert Logo',
            src: 'Zij_Schittert.png',
          }
          break
        case 'gold_en':
          this.picture = {
            alt: 'Zij Schittert Logo',
            src: 'She_Shines.png',
          }
          break
      }
    },
  },
  mounted() {
    this.getContent(this.lang)
  },
}
</script>

<style scoped lang="scss"></style>
