<template>
  <div>
    <div class="container fixed-top invisible">
      <b-row align-h="end" class="mx-0 mx-sm-100 py-2">
        <b-col cols="auto" align-self="center" class="px-0">
          <whatsapp-link variant="button" class="visible" />
        </b-col>
        <b-col cols="auto" align-self="center" class="ml-auto px-2 px-sm-3">
          <social-links class="socialLinks" />
        </b-col>
        <b-col cols="auto" align-self="center" class="px-0">
          <language-selector class="visible" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import WhatsappLink from './WhatsappLink'
import SocialLinks from './SocialLinks'
import LanguageSelector from './LanguageSelector'
import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'HeaderFixed',
  components: {
    WhatsappLink,
    SocialLinks,
    LanguageSelector,
    'b-row': BRow,
    'b-col': BCol,
  },
}
</script>
