<template>
  <b-button
    v-if="variant == 'button'"
    :variant="buttonVariant"
    :size="buttonSize"
    :href="wameLink"
    target="_blank"
    @click="onClick"
    :block="block"
  >
    <icon-base icon-name="whatsapp" :style="iconStyle"
      ><icon-whats-app
    /></icon-base>
    {{ $t('message.whatsapp.button') }}
  </b-button>
  <a v-else :href="wameLink" target="_blank" @click="onClick">
    {{ $t('message.whatsapp.button') }}
  </a>
</template>

<script>
import i18n from '../plugins/i18n'
import IconBase from './icons/IconBase.vue'
import IconWhatsApp from './icons/IconWhatsApp.vue'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'WhatsappLink',
  components: {
    IconBase,
    IconWhatsApp,
    'b-button': BButton,
  },
  props: {
    variant: String, // button, link
    buttonVariant: {
      type: String,
      default: 'light',
    },
    buttonSize: {
      type: String,
      default: 'sm',
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    wameLink: function () {
      return (
        'https://wa.me/31657630305?text=' + i18n.t('message.whatsapp.message')
      )
    },
    iconStyle: function () {
      return this.buttonVariant === '' ? 'filter: grayscale(1)' : ''
    },
  },
  methods: {
    onClick() {
      this.$ga.event('contact', 'whatsapp click')
    },
  },
}
</script>
