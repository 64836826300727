<template>
  <header class="sticky-top bg-dark">
    <div>
      <div class="container text-light">
        <b-row align-h="end" class="mx-0 mx-sm-100 py-2">
          <b-col cols="auto" align-self="center" class="px-0">
            <whatsapp-link variant="button" />
          </b-col>
          <b-col cols="auto" align-self="center" class="ml-auto px-2 px-sm-3">
            <social-links class="socialLinks" />
          </b-col>
          <b-col cols="auto" align-self="center" class="px-0">
            <language-selector />
          </b-col>
        </b-row>
      </div>
    </div>
  </header>
</template>

<script>
import WhatsappLink from './WhatsappLink'
import SocialLinks from './SocialLinks'
import LanguageSelector from './LanguageSelector'
import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'HeaderSticky',
  components: {
    WhatsappLink,
    SocialLinks,
    LanguageSelector,
    'b-row': BRow,
    'b-col': BCol,
  },
}
</script>

<style scoped lang="scss">
.socialLinks ::v-deep a {
  color: $white;
}
</style>
