<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewbox"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <g :fill="iconColor" alignment-baseline="middle">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 22,
    },
    height: {
      type: [Number, String],
      default: 22,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    isSquare: {
      type: [Boolean],
      default: true,
    },
  },
  computed: {
    viewbox: function () {
      return this.isSquare ? '0 0 64 64' : '0 0 64 48'
    },
  },
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: middle;
}
</style>
